const en = {
  mainTitle: 'Main title of your landing page',

  navFeatures: 'Features',
  navTeam: 'Team',
  navAbout: 'About',
  navLogIn: 'Log in',

  /*
    Landing page
  */
  landingTopTitle: 'Check & Go!',
  landingTopSubtitle: 'The student app by and for students',
  landingTopWeb: 'Use Quivr on your pc',
  landingTopMobile: 'Or download the app on your smartphone!',

  landingFeatUurrooster: 'Schedule',
  landingFeatUurroosterText:
    'Your schedule is available online and offline, and it tells you when and where you have class. You can also put your personal events in the Quivr schedule!',

  landingFeatBibmonitor: 'Library Monitor',
  landingFeatBibmonitorText:
    "Get info about the libraries and see how busy the libraries are so you can go to one where there's still room!",

  landingFeatVrienden: 'Friends',
  landingFeatVriendenText:
    'You can see when your friends have class, making it easier to meet eachother.',

  landingFeatRestaurant: 'Restaurant',
  landingFeatRestaurantText:
    "Lunch or dinner in your favourite student restaurant? See what's on the menu!",

  landingFeatNieuws: 'News',

  landingVoorWie: 'For who?',

  landingWaarom: 'Why Quivr?',
  landingWaaromText:
    'Quivr is a free application that centralizes useful information for students. On every smartphone or simply on your computer, Quivr allows you to quickly find the answers to questions such as: Where and when is my next class? How many people are in the library right now? Is there going to be spaghetti in Alma today?… Quivr is made by students, for students.',

  landingActief: 'Active users',
  landingDagelijks: 'Daily users',
  landingCampussen: 'Campuses',
  landingTeamleden: 'Team members',

  /*
    Team page
  */
  team: 'The Team',
  teamJoin: 'Join our team!',
  teamJoinText: 'Strengthen our team by checking out ',
  teamJoinPageText: 'our join page',
  teamJoinEmailText: 'send an email',

  /*
    Contact page
  */
  contactStudentTitle: 'Students',
  contactStudentPara1:
    'Are you part of a student organisation who wants to work with us? Or do you have any other interesting suggestions on how we could work together? Let us know by sending us an email at ',
  contactStudentPara2: 'Do you have questions or feedback for us?',
  contactStudentPara3a: 'Let us know by sending an email to ',
  contactStudentPara3b: '. We look forward to helping you further.',

  contactPartnerTitle: 'Partners',
  contactPartnerPara1:
    'Quivr brings together information that helps students, but this includes more than just the permanent campus facilities. We collaborate with companies, educational institutions and student associations to provide students with all sorts of additional, but always relevant, information. A partnership can go from supporting students throughout their student life by means of workshops, events, etc. to helping them with what comes next: finding an internship or a job that matches the studies and interests of each student.',
  contactPartnerPara2:
    'Do you want to become a partner that supports students and helps to get them started?',
  contactPartnerPara3a:
    'Let us know what you are looking for by sending us an email at ',
  contactPartnerPara3b:
    'Together, we always look for the best option for both you and the students.',

  /*
   Account deletion page
  */
  accountDeletionTitle: 'Deleting your account',
  accountDeletionIntro:
    'If you want to delete your account, there are two ways you can do this.',
  accountDeletionOption2: 'Option 2: Send an email to ',
  accountDeletionOption2Text:
    "Don't forget to provide the name and the email address of your account in the email.",
  accountDeletionOption1: 'Option 1: Delete your account yourself in the app.',
  accountDeletionOptionStep1: '1. Go to the "More" tab in the app.',
  accountDeletionOptionStep2: '2. Tap on "Personal information".',
  accountDeletionOptionStep3: '3. Tap on "Delete account".',
  accountDeletionOptionStep4:
    '4. Confirm that you want to delete your account.',

  accountDeletionDataInfoTitle: 'Data deletion and storage',
  accountDeletionDataInfoText: `When deleting your account all your personal data is removed from our production servers. Due to practical reasons it is possible that backups with your data will still be available after aforementioned
      erasure. These backups are however sufficiently encrypted and backups older than 2 months will be deleted
      automatically. A system is put in place that in case we need to restore our data, your data will not be
      restored to our production systems.`,
  accountDeletionMoreInfo: 'For more information please check our ',

  /*
    Join page
  */
  joinText:
    "Quivr wouldn't exist without the amazing team of dedicated students devoted to bringing Quivr and each other to new heights! We're always looking for more amazing people to join our team. Are you a developer, marketeer, legal advisor, designer, or savvy business person? Fill in this form!",

  /*
    Footer
  */
  footerMissie: 'Our mission',
  footerMissieText:
    'Our mission is to make the lives of our fellow students easier by bringing them the information they need.',
  footerFacebook: 'Follow us on Facebook!',
  footerTwitter: 'Follow us on Twitter!',
  footerInstagram: 'Follow us on Instagram',
  footerLinks: 'Important links',

  board: 'Board',
  backend: 'Back-end',
  frontend: 'Front-end',
  legal: 'Legal',
  design: 'Design',
  businessDevelopment: 'Business Development',
  marketing: 'Marketing',
  infrastructure: 'Infrastructure',
  contentProviders: 'Contentproviders',
  '24ul': '24 urenloop',
  developer: 'Developer',

  founder: 'Founder',
  president: 'President',
  vicePresident: 'Vice-president',
  treasurer: 'Treasurer',
  secretary: 'Secretary',
  boardMember: 'Board Member',
}

export default en
