import React from 'react'
import { PageContext } from '../../gatsby-node'
import { strings } from '../translations/localization'

const PageContextContext = React.createContext<PageContext>({
  originalPath: '/',
  lang: 'en',
})

export const PageContextProvider = ({
  value,
  children,
}: {
  value: PageContext
  children?: React.ReactNode
}) => {
  strings.setLanguage(value.lang)

  return (
    <PageContextContext.Provider value={value}>
      {children}
    </PageContextContext.Provider>
  )
}

export const usePageContext = () => React.useContext(PageContextContext)
