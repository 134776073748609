import React from 'react'
import { PageContextProvider } from './src/hooks/usePageContext'
import { GatsbySSR } from 'gatsby'
import { PageContext } from './gatsby-node'

// This SSR feature is solely used to set the lang attribute inside of the <html> tag.
// Gatsby is great btw
export const onRenderBody: GatsbySSR['onRenderBody'] = ({
  pathname,
  setHtmlAttributes,
}) => {
  setHtmlAttributes({ lang: pathname.includes('nl') ? 'nl' : 'en' })
}

/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
export const wrapPageElement: GatsbySSR['wrapPageElement'] = ({
  element,
  props,
}) => {
  // This cast is valid since all pages created by gatsby will have a page context of type PageContext.
  // Page creation and type definition both reside in gatsby-node.ts
  const context = props.pageContext as PageContext
  return <PageContextProvider value={context}>{element}</PageContextProvider>
}
