const nl = {
  mainTitle: 'Het werkt',

  navFeatures: 'Features',
  navTeam: 'Team',
  navAbout: 'Over ons',
  navLogIn: 'Log in',

  /*
    Landing page
  */
  landingTopTitle: 'Check & Go!',
  landingTopSubtitle: 'De studentenapp voor en door studenten',
  landingTopWeb: 'Gebruik Quivr op je pc',
  landingTopMobile: 'Of download de app op je smartphone!',

  landingFeatUurrooster: 'Uurrooster',
  landingFeatUurroosterText:
    'Je uurrooster dat zowel on- als offline raadpleegbaar is, vertelt je steeds waar en wanneer je lessen doorgaan. Zet er ook je eigen afspraken in en zo biedt Quivr jou een persoonlijke agenda aan.',

  landingFeatBibmonitor: 'Bibmonitor',
  landingFeatBibmonitorText:
    'De bibmonitor toont je de bezettingsgraad van de verschillende bibliotheken, zodat je meteen weet waar er nog plaats is om te gaan studeren.',

  landingFeatVrienden: 'Vrienden',
  landingFeatVriendenText:
    'Naast jouw eigen uurrooster kan je ook het uurrooster van je vrienden zien, zodat je perfect weet wanneer jullie kunnen afspreken.',

  landingFeatRestaurant: 'Restaurant',
  landingFeatRestaurantText:
    "'s Middags of 's avonds eten in jouw favoriete studentenrestaurant? Bekijk op voorhand de openingsuren en wat er op het menu staat!",

  landingFeatNieuws: 'Nieuws',

  landingVoorWie: 'Voor wie?',

  landingWaarom: 'Waarom Quivr?',
  landingWaaromText:
    'Quivr is een gratis applicatie die nuttige informatie voor de student op één plaats samenbrengt. Zowel op smartphone, tablet als PC biedt Quivr de mogelijkheid om razendsnel antwoord te vinden op vragen als: Waar en wanneer heb ik mijn volgende les? Hoeveel mensen zitten er momenteel in de bib? Kan ik deze middag spaghetti eten in de Alma?… Elke dag opnieuw probeert Quivr zo het leven van studenten gemakkelijker te maken.',

  landingActief: 'Actieve gebruikers',
  landingDagelijks: 'Dagelijkse gebruikers',
  landingCampussen: 'Campussen',
  landingTeamleden: 'Teamleden',

  /*
    Team page
  */
  team: 'Het Team',
  teamJoin: 'Join ons team!',
  teamJoinText: 'Versterk ons team en bekijk',
  teamJoinPageText: 'onze join-pagina',
  teamJoinEmailText: 'verstuur een mailtje',

  /*
    Contact page
  */

  contactStudentTitle: 'Studenten',
  contactStudentPara1:
    'Ben jij lid van een studentenorganisatie die met ons wil samenwerken? Of heb je andere interessante ideeën voor een samenwerking? Laat het ons weten via ',
  contactStudentPara2: 'Heb je vragen of feedback voor ons?',
  contactStudentPara3a: 'Laat het ons weten door een mailtje te sturen naar ',
  contactStudentPara3b: '. We helpen je graag verder.',

  contactPartnerTitle: 'Partners',
  contactPartnerPara1:
    'Quivr brengt informatie samen die studenten helpt. Dat is meer dan enkel de vaste campusfaciliteiten. We werken samen met bedrijven, onderwijsinstellingen en studentenverenigingen om de juiste studenten te bereiken met informatie die voor hen interessant is. Partnerschappen gaan van het ondersteunen van studenten bij het studeren tot wat daarna komt: het vinden van een stage of een job die aansluit bij de studies en de interesse van iedere student.',
  contactPartnerPara2:
    'Wil u een partner worden die studenten ondersteunt en op weg helpt?',
  contactPartnerPara3a:
    'Laat ons weten waar u naar op zoek bent door een mailtje te sturen naar',
  contactPartnerPara3b:
    'We zoeken altijd samen de juiste oplossing voor u en de student.',

  /* 
    Account deletion page
  */
  accountDeletionTitle: 'Je account verwijderen',
  accountDeletionIntro:
    'Als je je account wilt verwijderen, zijn er twee manieren om dit te doen.',
  accountDeletionOption2: 'Optie 2: Stuur een e-mail naar ',
  accountDeletionOption2Text:
    'Gelieve de naam en het e-mailadres van je account in de e-mail te vermelden.',
  accountDeletionOption1: 'Optie 1: Verwijder je account zelf in de app.',
  accountDeletionOptionStep1: '1. Ga naar de tab "Meer" in de app.',
  accountDeletionOptionStep2: '2. Tik op "Persoonlijke informatie".',
  accountDeletionOptionStep3: '3. Tik op "Verwijder account".',
  accountDeletionOptionStep4: '4. Bevestig dat je je account wilt verwijderen.',

  accountDeletionDataInfoTitle: 'Data verwijdering en opslag',
  accountDeletionDataInfoText: `Wanneer je je account verwijdert, worden al je persoonlijke gegevens van onze productieservers verwijderd. Om praktische redenen is het mogelijk dat back-ups met uw gegevens na bovengenoemde verwijdering nog steeds beschikbaar zijn. Deze back-ups zijn echter
        voldoende versleuteld en back-ups ouder dan 2 maanden worden automatisch verwijderd. Er is een systeem opgezet dat, in het geval dat we onze gegevens
        moeten herstellen, uw gegevens niet worden hersteld naar onze productiesystemen.`,
  accountDeletionMoreInfo:
    'Voor meer informatie over ons privacybeleid bekijk je best onze ',

  /* 
    Join page
  */
  joinText:
    'Quivr kan niet bestaan zonder het fantastische team van toegewijde studenten die elke dag hun schouders achter dit project zetten om Quivr en elkaar naar een hoger niveau te tillen. We zijn altijd op zoek naar meer geweldige mensen om ons team te versterken! Developer, marketeer, legal advisor, designer of business developer? Vul dit formulier in!',

  /*
    Footer
  */
  footerMissie: 'Onze Missie',
  footerMissieText:
    'Het leven van studenten makkelijker maken door informatie dichter dan ooit bij hen te brengen.',
  footerFacebook: 'Volg ons op Facebook!',
  footerTwitter: 'Volg ons op Twitter!',
  footerInstagram: 'Volg ons op Instagram!',
  footerLinks: 'Belangrijke links',

  board: 'Bestuur',
  backend: 'Backend',
  frontend: 'Frontend',
  legal: 'Legal',
  design: 'Design',
  businessDevelopment: 'Bedrijvenrelaties',
  marketing: 'Marketing',
  infrastructure: 'Infrastructuur',
  contentProviders: 'Contentproviders',
  '24ul': '24 urenloop',
  developer: 'Ontwikkelaar',

  founder: 'Oprichter',
  president: 'Voorzitter',
  vicePresident: 'Ondervoorzitter',
  treasurer: 'Penningmeester',
  secretary: 'Secretaris',
  boardMember: 'Bestuurder',
}

export default nl
